import React, { useState } from "react";
import CloseIcon from "./icon-close.svg";
import styles from './styles.module.css'

const Banner = ({ id, message, cta, ctaLink, isOnTop=false }) => {
  const [closed, setClosed] = useState(false);
  const handleClick = () => {
    setClosed(true);
    const savedBanners = JSON.parse(localStorage.getItem(`closed_banners`));
    const closedBanners = savedBanners || { closed:{} };
    const currTimestamp = new Date().getTime();
    closedBanners.lastClosed = currTimestamp;
    closedBanners.closed[id] = true;
    localStorage.setItem(`closed_banners`, JSON.stringify(closedBanners));
  }
  if (closed) {
    return <></>
  }
  return (
    <div className={`${styles.banner} ${!isOnTop && styles.bottomBanner}`} role="alert">
      <div className={styles.content}>
        <div className={styles.message}>
          <span className={styles.desktopMessage}>{message}</span>
          <span className={styles.mobileMessage}>
            <a className={styles.ctaLink} href={ctaLink} target="_blank" and rel="noopener noreferrer">
              <span className={styles.ctaLinkText}>{message}</span> <span className={styles.ctaLinkCall}>{cta}</span>
            </a>
          </span>
        </div>
        <div className={styles.cta}>
          <a className={styles.ctaLinkBtn} href={ctaLink} target="_blank" and rel="noopener noreferrer">{cta}</a>
        </div>
      </div>
      <button className={styles.closeBtn} onClick={handleClick}><CloseIcon /></button>
    </div>
  );
}

export default Banner
