import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout/index'
import Index from "../components/MetaTags/index";
import styles from "./about.module.css"
import coverImage from "./about/cover-encyclopedia-gallactica.jpg";

const AboutPage = (props) => (
  <Layout>
    <Index
      title={`About Graphopedia`}
      description="Graphopedia contains the biggest collection of data visualizations and inforaphics. Read more about how it works."
    />
    <div className={styles.cover}><img src={coverImage} /></div>
    <h1>About Graphopedia</h1>
    <p>The Graphopedia is a compilation of chart, diagram, and visualization model types used in information graphics.
      The list includes general-purpose visualization types. Each types is given a definition, its anatomy, examples, tools for creating the visualization, and the sources.
    </p>
    <p>Join <Link to="/about/dao">our DAO</Link></p>
    <h2>How to Find The Chart Your Are Looking For</h2>
    <p>All charts are shown in alphabetical order. You can see them as a grid of icons or as a list with definitions. You can search for a chart type using the search functionality in the top left. If the chart type you are looking for is a variation of another chart, you will find it in the Variations section.</p>
    <p>The more commonly used charts have their variations listed in the Variations section. Some types of variations apply across many types of charts, for example stacking (as in stacked area chart), grouping (as in grouped bar chart), and radial layout (as in radial tree). Other types of variations are unique to a specific chart type.</p>
    <p>For many charts their alternative charts are listed in the Alternatives section. Alternative charts are those that can represent the same data structure. They may not be visually similar but they are the same in their function.</p>
    <p>When it comes to choosing the right type of chart for an infographic, the possibilities are unlimited. The list here focuses on visualization types that have been described in scientific literature such that we know their advantages and disadvantages, and most importantly how readers interact with them.</p>
    <p>Some chart types are popular in presentations and business but not researched by scientists. Such examples include the bullet chart, the waterfall chart, the pyramid diagram, and the funnel chart, among others. We still included them because they are widely used and often for their role as a metaphor rather than a statistical graphic.</p>
    <p>The example section attempts to give a variety of high-quality examples, most of them coming from newspapers. We attempt to make every example stand on its own so that the reader can understand the underlying data and see how the visualization technique was applied to it.</p>
   <p>While many of the listed charts are types of data visualization, meaning statistical graphs, many are pure information graphics techniques that include visual metaphor, illustrations, and diagrams. We use the term "information graphics" to include both data visualizations and infographics.</p>
    <p>When listing sources, we attempt to link to the PDF version of the document that you can read where it is possible.</p>
    <h2>Why We Created Infographopedia</h2>
    <p>We are information designers who build infographic-making software Adioma. We have to know what chart and diagram types are out there and how people use them. We also need to see the best examples of them to keep up our quality of infographics and icons.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AboutPage
