import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'normalize.css'
import '../../styles/global.css'
import styles from './styles.module.css'

import Header from '../Header/index'
import Footer from "../Footer"
import Banner from "../Banner";

const Layout = ({ children, linksSlot, type }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        allAnnouncementsYaml {
          nodes{
            id
            description
            cta
            link
            ontop
            delay
            expiration
          }
        }
      }
    `}
    render={data => {
      const banner = getCurrentBanner(data.allAnnouncementsYaml.nodes);
      return(
        <div className={styles.globalWrapper}>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'My fav blog' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <div className={styles.content}>
            {banner &&
              <Banner
                id={banner.id}
                message={banner.description}
                cta={banner.cta}
                ctaLink={banner.link}
                isOnTop={banner.ontop}
              />
            }
            <Header siteTitle={data.site.siteMetadata.title} linksSlot={linksSlot} />

            <div className={type === "full" ? styles.pageFull : styles.pageColumn}>
              {children}
            </div>
          </div>

          <Footer />
        </div>
    )}}
  />
)

function getCurrentBanner(banners) {
  const closedBanners = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem(`closed_banners`)) : undefined;
  for (let i = 0; i < banners.length; i++) {
    const { id, delay, expiration } = banners[i];
    if (!closedBanners?.closed[id]) {
      // check if banner announcement hasn't expired
      const expirationTimestamp = expiration && new Date(expiration).getTime();
      const now = new Date().getTime();
      if (expirationTimestamp && (now > expirationTimestamp) ) {
        continue; // if expired, try next banner
      }
      //check if banner should be delayed
      const {lastClosed} = closedBanners || {};
      if (lastClosed && delay) {
        const delayMS = getDelayPeriodInMS(delay);
        const delayedTime = lastClosed + delayMS;
        if (delayedTime > now) {
          return; //reject the banner and don't show anything else
        };
      }
      return banners[i]; // no expiration or delay, display curr. banner
    }
  }
  return null; // no more banners left
}

const getDelayPeriodInMS = (delay) => {
  const [delayValue, delayPeriod="s"] = delay.split(" ");
  const delayTime = Number(delayValue);
  switch (delayPeriod) {
    case "s": return delayTime * 1000;
    case "m": return delayTime * 1000 * 60;
    case "h": return delayTime * 1000 * 60 * 60;
    case "d": return delayTime * 1000 * 60 * 60 * 24;
    default: return delayTime * 1000;
  }
}

export default Layout
