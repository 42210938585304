import React from 'react'
import { Link } from 'gatsby'
import styles from './styles.module.css'

const Footer = () => (
  <footer className={styles.footer}>
    <ul className={styles.menu}>
      <li className={styles.home}><Link to="/">Adioma © {new Date().getFullYear()}</Link></li>
      <li><Link to="/about">About</Link></li>
      <li><a href="https://adioma.com">Infographic Maker</a></li>
      <li className="hidden-md"><a href="https://adioma.com/icons">Icons</a></li>
      <li className="hidden-md" ><a href="https://learn.adioma.com">Infographic Course</a></li>
      <li className=""><a href="https://blog.adioma.com">Blog</a></li>
      <li className="hidden-md hidden-lg"><a href="https://github.com/graphopedia-xyz/graphopedia">Contribute</a></li>
      <li><a href="https://adioma.com/terms">Terms<span className="= hidden-md hidden-lg"> Of Use</span></a></li>
      <li><a href="https://adioma.com/privacy">Privacy</a></li>
    </ul>
  </footer>
)

export default Footer
